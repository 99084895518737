import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService, L, MultilingualstringConfigService } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ClientEnv, Config, HijiBundle, HijiRight, ProfileService } from 'common';

import { LocaleLoader } from '../LocaleLoader';

@Component({
  selector: 'hiji-back-layout',
  templateUrl: './back-layout.component.html',
  styleUrls: ['./back-layout.component.scss'],
})
export class BackLayoutComponent implements OnInit {
  foUrl;
  show: boolean = false;
  appName: string = 'hiji';
  menus: Array<{
    name;
    nameMS?;
    alternateName?;
    module?: string | string[];
    links: Array<{ name; alternateName?; icon; url; exact?: boolean; routerActive?: () => boolean; module?: string | string[]; rights?: HijiRight[] }>;
    rights?: HijiRight[];
  }> = [
    {
      name: 'Bienvenue',
      links: [{ name: 'Accueil', icon: 'fa-light fa-home', url: '/dashboard', exact: true }],
      rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
    },
    {
      name: 'EMA',
      module: 'ema',
      links: [
        {
          name: 'Template',
          icon: 'fa-light fa-handshake',
          url: '/ema/template',
          module: 'ema',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
        },
        {
          name: 'Statistiques',
          icon: 'fa-light fa-chart-line',
          url: '/ema/stats/participation',
          module: 'ema',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
          routerActive: () => {
            return this.router.url.startsWith('/ema/stats');
          },
        },
        {
          name: 'Fichiers KPI',
          icon: 'fa-light fa-file-import',
          url: '/ema/kpi-files',
          module: 'ema',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
          routerActive: () => {
            return this.router.url.startsWith('/ema/kpi-import-management');
          },
        },
        { name: 'Avis', icon: 'fa-light fa-ticket', url: 'opinion', module: 'opinionEnabled', rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE] },
      ],
      rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
    },
    {
      name: 'Campagnes',
      module: ['campagne', 'fbs', 'restitution'],
      links: [
        {
          name: 'Campagne',
          alternateName: 'Enquêtes',
          icon: 'fa-light fa-bullhorn',
          url: '/campaign',
          module: 'campagne',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE, HijiRight.CONSULTATION_STATS],
        },
        { name: 'Feedback', icon: 'fa-light fa-question-circle', url: '/feedback', module: 'fbs', rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE] },
        { name: 'Restitution', icon: 'fa-light fa-poll-h', url: '/restitution', module: 'restitution', rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE] },
        { name: 'Avis', icon: 'fa-light fa-ticket', url: 'opinion', module: 'opinionEnabled', rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE] },
        {
          name: 'Verbatims',
          icon: 'fa-light fa-file-lines',
          url: '/verbatims/categorize',
          module: 'enquete',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
        },
      ],
      rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE, HijiRight.CONSULTATION_STATS],
    },

    {
      name: 'Certification',
      module: 'certification',
      links: [
        {
          name: 'Quiz',
          icon: 'fa-light fa-clipboard-question',
          url: '/r/quiz',
          module: 'certification',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
        },
        { name: 'Roleplay / Interview', icon: 'fa-light fa-clipboard-list', url: '/r/repository', rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE] },
        {
          name: 'Modalités',
          icon: 'fa-light fa-bullseye-arrow',
          url: '/modality',
          module: 'certification',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
        },
        {
          name: 'Certification',
          icon: 'fa-light fa-certificate',
          url: '/certification',
          module: 'certification',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
          routerActive: () => {
            return this.router.url.startsWith('/certification') && !this.router.url.startsWith('/certification/stats');
          },
        },
        {
          name: 'Statistiques',
          icon: 'fa-light fa-chart-line',
          url: '/certification/stats/interview',
          module: 'certification',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
          routerActive: () => {
            return this.router.url.startsWith('/certification/stats');
          },
        },
      ],
      rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
    },
    {
      name: 'Impact Formation',
      module: 'mesureImpactFormation',
      links: [
        {
          name: 'Formation',
          icon: 'fa-light fa-chalkboard-user',
          url: '/formation',
          module: 'mesureImpactFormation',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
          routerActive: () => {
            return (
              this.router.url.startsWith('/formation') &&
              !this.router.url.startsWith('/formation/stats') &&
              !this.router.url.startsWith('/formation/training-path')
            );
          },
        },
        {
          name: 'Statistiques',
          icon: 'fa-light fa-chart-line',
          url: '/formation/stats/satisfaction',
          module: 'mesureImpactFormation',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
          routerActive: () => {
            return this.router.url.startsWith('/formation/stats');
          },
        },
        {
          name: 'Parcours de formation',
          icon: 'fa-light fa-signs-post',
          url: '/formation/training-path',
          module: 'TMS',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
          routerActive: () => {
            return this.router.url.startsWith('/formation/training-path');
          },
        },
      ],
      rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
    },
    {
      name: 'Évenements',
      module: 'event',
      links: [
        {
          name: 'Évenements',
          icon: 'fa-light fa-calendar-star',
          url: '/event',
          module: 'event',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
          routerActive: () => {
            return this.router.url.startsWith('/event') && !this.router.url.startsWith('/event/stats');
          },
        },
        {
          name: 'Statistiques Évenements',
          icon: 'fa-light fa-chart-line',
          url: '/event/stats/global-satisfaction',
          module: 'event',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
          routerActive: () => {
            return this.router.url.startsWith('/event/stats');
          },
        },
      ],
      rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
    },
    {
      name: 'Référentiels',
      links: [
        { name: 'Référentiels', alternateName: 'Questionnaires', icon: 'fa-light fa-clipboard-list', url: '/r/repository' },
        { name: 'Gestes observables', alternateName: 'Questions', icon: 'fa-light fa-handshake', url: '/r/observable-gesture' },
        { name: 'Critères', icon: 'fa-light fa-palette', url: '/r/criteria' },
        { name: 'Compétences', alternateName: 'Catégories', icon: 'fa-light fa-tools', url: '/r/tag' },
        { name: 'Quiz', icon: 'fa-light fa-clipboard-question', url: '/r/quiz', module: ['certification', 'mesureImpactFormation'] },
      ],
      rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
    },
    {
      name: 'Réseau',
      links: [
        { name: 'Organisation', icon: 'fa-light fa-building', url: '/network/organisation' },
        { name: 'Fonction', icon: 'fa-light fa-tag', url: '/network/function' },
        { name: 'Population', icon: 'fa-light fa-users', url: '/network/population' },
        {
          name: 'Listes de diffusion',
          icon: 'fa-light fa-tower-broadcast',
          url: '/network/diffusion-list',
          module: 'TMS',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
          routerActive: () => {
            return this.router.url.startsWith('/network/diffusion-list');
          },
        },
      ],
      rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
    },
    {
      name: 'Utilisateurs',
      links: [{ name: 'Utilisateurs', icon: 'fa-light fa-user', url: '/user' }],
      rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
    },
    {
      name: 'Configuration',
      links: [
        { name: 'Modules', icon: 'fa-light fa-cubes', url: 'configuration/modules', rights: [HijiRight.ADMIN] },
        { name: 'Pages Statiques', icon: 'fa-light fa-file', url: 'configuration/static-page', rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE] },
        { name: 'Email génériques', icon: 'fa-light fa-envelope', url: 'configuration/email', rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE] },
        { name: 'Feedbacks categories', icon: 'fa-light fa-list-alt', url: 'configuration/fbs-category', rights: [HijiRight.ADMIN] },
        { name: 'Verbatim categories', icon: 'fa-light fa-file-lines', url: '/verbatims/categories', rights: [HijiRight.ADMIN] },
        { name: 'Configuration rapports 180', icon: 'fa-light fa-file-pdf', url: 'configuration/report-180', rights: [HijiRight.ADMIN] },
        {
          name: 'Configuration rapports EMA',
          icon: 'fa-light fa-file-pdf',
          url: 'configuration/report-ema',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
        },
        { name: 'Configuration Front', icon: 'fa-light fa-wrench', url: 'configuration/config-front', rights: [HijiRight.ADMIN] },
        { name: 'Configuration Back', icon: 'fa-light fa-wrench', url: 'configuration/config-back', rights: [HijiRight.ADMIN] },
        { name: 'Configuration Manifest', icon: 'fa-light fa-cog', url: 'configuration/config-manifest', rights: [HijiRight.ADMIN] },
        { name: 'Customisation CSS', icon: 'fa-brands fa-css3-alt', url: 'configuration/css-editor', rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE] },
        {
          name: 'Traduction',
          icon: 'fa-light fa-globe',
          url: 'configuration/translation',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE, HijiRight.TRADUCTEUR],
        },
        {
          name: 'Configuration Périmètre',
          icon: 'fa-light fa-fence',
          url: 'configuration/perimeter-user/view',
          rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE],
        },
      ],
      rights: [HijiRight.ADMIN, HijiRight.GESTIONNAIRE, HijiRight.TRADUCTEUR],
    },
  ];
  showAlternateNames: boolean = false;
  sortedAvailableLanguages: string[];
  currentLang: string;
  isDarkMode: boolean;

  constructor(
    public authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    public multilingualstringConfigService: MultilingualstringConfigService,
    public profileService: ProfileService,
    public env: EnvService<Config>,
    public clientEnv: ClientEnv
  ) {
    this.currentLang = translate.currentLang || translate.defaultLang;
    translate.onLangChange.subscribe((evt) => {
      this.currentLang = evt.lang;
    });
    this.foUrl = env.configuration.foUrl;
    this.appName = env.configuration.appTitle;
    this.sortedAvailableLanguages = this.multilingualstringConfigService.availableLanguages.sort((a, b) =>
      this.translate.instant('common.languages.' + a).localeCompare(this.translate.instant('common.languages.' + b))
    );
    const hijiBundle = this.authService.userBundle.data as HijiBundle;
    this.menus[1].nameMS = hijiBundle.modules.emaName;
  }

  ngOnInit(): void {
    this.showAlternateNames = (this.authService.userBundle.data as HijiBundle).modules.changeBONamesToSurveyMode;
    if (localStorage.getItem('DarkMode') === null) {
      this.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (this.isDarkMode) localStorage.setItem('DarkMode', 'true');
      if (!this.isDarkMode) localStorage.setItem('DarkMode', 'false');
    }
    if (localStorage.getItem('DarkMode') === 'false') this.isDarkMode = false;
    if (localStorage.getItem('DarkMode') === 'true') this.isDarkMode = true;
    this.applyTheme();
  }

  toggleMenu() {
    this.show = !this.show;
  }
  hideMenu() {
    this.show = false;
  }

  hasModuleEnabled(module: string | Array<string>) {
    const hijiBundle = this.authService.userBundle.data as HijiBundle;
    if (Array.isArray(module)) {
      return module.map((m) => this.hasModuleEnabled(m)).reduce((a, b) => a || b);
    } else {
      if (module === 'campagne') return hijiBundle.modules.moduleCampaign || hijiBundle.modules.moduleEnquete || hijiBundle.modules.moduleCampaign360;
      if (module === 'enquete') return hijiBundle.modules.moduleEnquete;
      if (module === 'fbs') return hijiBundle.modules.moduleFBS;
      if (module === 'restitution') return hijiBundle.modules.moduleBilan;
      if (module === 'certification') return hijiBundle.modules.moduleCertification;
      if (module === 'mesureImpactFormation') return hijiBundle.modules.moduleMesureImpactFormation;
      if (module === 'ema') return hijiBundle.modules.moduleEMA;
      if (module === 'opinionEnabled') return hijiBundle.modules.opinionEnabled;
      if (module === 'event') return hijiBundle.modules.moduleEvent;
      if (module === 'TMS') return hijiBundle.modules.moduleTMS;
    }
    return false;
  }

  changeLanguage(language: string) {
    localStorage.setItem('language', language);
    this.profileService.updateLocale(language).subscribe(
      (data) => {
        LocaleLoader.load(language).then(() => {
          this.translate.use(language);
          this.currentLang = this.translate.currentLang || this.translate.defaultLang;
          this.sortedAvailableLanguages = this.multilingualstringConfigService.availableLanguages.sort((a, b) =>
            this.translate.instant('common.languages.' + a).localeCompare(this.translate.instant('common.languages.' + b))
          );
        });
        window.location.href = window.location.href;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  logout() {
    this.authService.logout();
    localStorage.setItem('forcelogin', 'true');
    this.router.navigateByUrl('/logout');
  }

  hasRight(rights: HijiRight[]) {
    return this.authService.has(...rights);
  }

  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    if (this.isDarkMode) localStorage.setItem('DarkMode', 'true');
    if (!this.isDarkMode) localStorage.setItem('DarkMode', 'false');
    this.applyTheme();
  }

  private applyTheme() {
    if (localStorage.getItem('DarkMode') === 'true') {
      document.body.parentElement.classList.add('dark-mode');
    } else {
      document.body.parentElement.classList.remove('dark-mode');
    }
  }
}
