import { Component, OnInit } from '@angular/core';
import { hijiVersion } from 'environments/version';

@Component({
  selector: 'hiji-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  version = hijiVersion;
  constructor() {}

  ngOnInit(): void {}
}
