<hiji-wavy-bg>
  <div title>
    <h5>{{ 'support.title' | translate }}</h5>
  </div>
  <div class="container-fluid">
    <hiji-static-page [fullPage]="false"></hiji-static-page>
    <div class="row mt-4">
      <div class="col">Version : {{ version.branch }} {{ version.hash }} {{ version.date }}</div>
    </div>
  </div>
</hiji-wavy-bg>
