import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from 'environments/environment';
import { AppModule } from './app/app.module';

//registerLocaleData(localefr);
//registerLocaleData(localeen);

if (environment.production) {
  enableProdMode();
} else {
  //import('zone.js/plugins/zone-error');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    let message = '';

    const loadingElement = document.querySelector('#LoadingElement') as HTMLElement;
    const errorMsgElement = document.querySelector('#errorMsgElement') as HTMLElement;
    const refreshButton = document.querySelector('#refreshButton') as HTMLButtonElement;

    loadingElement.style.display = 'none';

    if (err.message == 'Time') message = 'Loading in progress, please hang tight';
    else if (err.message == 'Down') message = 'An error occurred, please try again in a few seconds';

    errorMsgElement.textContent = message;
    refreshButton.style.display = 'block';
  });
