import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, CSP_NONCE, Inject, OnInit, Renderer2 } from '@angular/core';
import { EnvService } from '@ic2/ic2-lib';
import { AuthService, Config, HijiRight } from 'common';

@Component({
  selector: 'hiji-lemon',
  templateUrl: './lemon.component.html',
  styleUrl: './lemon.component.scss',
})
export class LemonComponent implements OnInit, AfterViewInit {
  public readonly trustedTypePolicy: TrustedTypePolicy;
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private authService: AuthService,
    private env: EnvService<Config>,
    @Inject(CSP_NONCE) private CSP_NONCE: string
  ) {
    if (window.trustedTypes == undefined) {
      (window as any).trustedTypes = {
        createPolicy: (_name: string, rules: TrustedTypePolicyOptions) => rules,
      };
    }

    // Creating the policy
    this.trustedTypePolicy = window.trustedTypes.createPolicy('hiji#safe', {
      createHTML: (input) => {
        throw new Error('Not allowed');
      },
      createScript: (input) => {
        return input;
      },
      createScriptURL: (input) => {
        return input;
      },
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    //<link href="https://static.lemonlearning.com/player/bundle.css" rel="stylesheet" type="text/css">
    let css = this._renderer2.createElement('link');
    css.href = `https://static.lemonlearning.com/player/bundle.css`;
    css.rel = 'stylesheet';
    css.type = 'text/css';
    this._renderer2.appendChild(this._document.body, css);

    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.setAttribute('nonce', this.CSP_NONCE);

    script.text = this.trustedTypePolicy.createScript(`
          function LemonLearningReady (player) {
              player
                  .set({
                      projectKey : '${this.env.configuration.lemonProjectKey}',
                      user : {
                          name : '${this.authService.userBundle.user.firstName} ${this.authService.userBundle.user.lastName}',
                          email : '${this.authService.userBundle.user.email}',
                          profiles : [5249],
                          tags : [
                              {category : "Client", values : ["${this.env.configuration.client}"]},
                              {category : "Role", values : [${this.authService.userBundle.rights.map((r) => '"' + HijiRight.mapFromId(r.idRight).lib + '"').join(', ')}]}
                          ]
                      }
                  })
                  .start()
          }
        `);
    this._renderer2.appendChild(this._document.body, script);
    let s2 = this._renderer2.createElement('script');
    s2.src = this.trustedTypePolicy.createScriptURL(`https://static.lemonlearning.com/player/bundle.js`);
    s2.async = '';
    s2.defer = '';
    s2.charset = 'utf-8';
    this._renderer2.appendChild(this._document.body, s2);
  }
}
